<template>
	<div style="height: 100vh;">
        
        <iframe src="https://apis.map.qq.com/uri/v1/marker?marker=coord:25.096991,104.899523;title:兴义市第四中学;tel:88888888;addr:兴义市延安路与栖霞路路口&referer=JASBZ-BFT3F-LZYJX-NTUXJ-FMAVK-GSFSL" frameborder="0" width="100%" height="100%"></iframe>
        
	</div>
</template>



<script>
	
	
	export default {
		components:{
		},
		data() {
			return {
				
			}
		},
		mounted() {
			
            
		}
		
	}
</script>

<style>
</style>
